import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const ShareholderEngagementPolicy = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <LandingBanner title="Shareholder Engagement Policy" alt />
      <section className="internal-page__section">
        <h2>
          <b>
            W&amp;W Asset Management Ireland DAC Shareholder Engagement Policy &amp; The
            Shareholders Rights Directive (SRD II)
          </b>
        </h2>
        <p>
          The Shareholder Rights Directive 2017/828 (SRD II) amends Directive 2007/36 (SRD I) and
          promotes long-term shareholder engagement and enhances the transparency between EU-listed
          companies and their investors.
        </p>
        <p>
          The Shareholders’ Rights Regulations 2020 require asset management companies to develop
          and publicly disclose an engagement policy that describes how they integrate shareholder
          engagement in their investment strategy. W &amp; W Asset Management Ireland DAC (“WWAMI” /
          the “Firm”) has established this policy in compliance with SRD II requirements.
        </p>
        <p>
          The Firm is authorised by the Central Bank as an Alternative Investment Fund Manager
          pursuant to the AIFMD Regulations and as a UCITS Manager pursuant to the UCITS
          Regulations. The Firm is deemed to be an “asset manager” under SRD II.
        </p>
        <p>
          In 2022, WWAMI received approval from the Central Bank of Ireland to extend it
          authorisation for the Management of portfolios of investments, Investment Advice and
          Reception and Transmission of orders. The Firm currently provides these (inhouse) services
          to three sub-funds of W&amp;W Global Investments Fund as described below. The Firm also
          delegated day-to-day investment management of a number of funds to third party asset
          managers (“sub-investment managers”), as described below.
        </p>
        <h3>Inhouse Investment Management Services</h3>
        <p>
          For the purposes of SRD II, (WWAMI) is identified as a relevant asset manager. WWAMI is an
          investment manager “that invests in shares traded on a regulated market on behalf of
          investors” and therefore is categorised as a relevant asset manager (Ref: 1110F of the
          S.I. 81/2020). The Shareholder Engagement and Annual Engagement and Investor (Client)
          Reporting Policies below apply to the following sub-funds of the W&amp;W Global
          Investments Fund:
        </p>
        <ul>
          <li>W&amp;W Flexible Point and Figure </li>
          <li>W&amp;W Flexible Premium</li>
          <li>W&amp;W Flexible Premium II </li>
        </ul>
        <p>
          New funds and their applicability under SRD II for the engagement and annual reporting
          policies will be assessed on a per fund basis.
        </p>
        <p>
          WWAMI complies with its obligations Article 3j of the SRD as amended by SRD II and,
          sections 1110H &amp; 1110I &amp; 1110J of the S.I. 81/2020 by enacting the below policies:
        </p>
        <h3>Third Party Investment Managers</h3>
        <p>
          WWAMI has delegated the investment management function for a number of funds under its
          management to various third party Investment Managers. Therefore, the Firm shall rely on
          the policies of each relevant Investment Manager. The Firm has policies and procedures in
          place to maintain on-going oversight of their delegated activities.
        </p>
        <p>
          On an annual basis, each Investment Manager will publicly disclose how their engagement
          policy has been implemented.
        </p>
        <p>
          Each relevant Investment Manager will make this information available free of charge on
          their website.
        </p>
        <h3>Shareholder Engagement Policy</h3>
        <p>
          Under Article 3g of SRD II, asset managers are required to develop and publicly disclose
          an engagement policy that describes how they integrate shareholder engagement in their
          investment strategy. This policy should describe how the Firm monitors investee companies
          on relevant matters including:
        </p>
        <p>
          <ul>
            <li>
              Integration of shareholder engagement in its investment strategy;
              <ul>
                <li>
                  The level of shareholder engagement is considered as part of the fund investment
                  strategy by the fund manager, advisors and investment committee of the investment
                  manager. Currently, due to the nature and the size of the holdings in relevant
                  investee companies, the investment policy is that direct shareholder engagement is
                  not required or would be in the best interest of the fund/investor.
                </li>
              </ul>
            </li>
            <li>
              Monitoring strategy, financial and non-financial performance and risk and capital
              structure;
              <ul>
                <li>
                  These activities are sub delegated to the fund advisor as mandated through an
                  Investment Advisory Agreement.
                </li>
              </ul>
            </li>
            <li>
              Monitoring social and environmental impact and corporate governance
              <ul>
                <li>
                  WWAMI is a wholly owned subsidiary of W&amp;W Asset Management GMBH which is part
                  of the W&amp;W Gruppe. Wüstenrot &amp; Württembergische (W&amp;W Gruppe) is a
                  German financial services group, based in Stuttgart. The W&amp;W Gruppe has
                  enacted an ESG policy and has signed the principles of UNPRI (Principles for
                  Responsible Investment) as an Asset Owner as well as the UNPSI (Principles for
                  Sustainable Insurance).
                  <ul>
                    <li>
                      Link to UNPRI -{' '}
                      <a href="https://www.unpri.org/signatory-directory/wandw-gruppe/5775.article">
                        https://www.unpri.org/signatory-directory/wandw-gruppe/5775.article
                      </a>
                    </li>
                    <li>
                      Link to PSI -{' '}
                      <a href="https://www.unepfi.org/psi/ww-group/">
                        https://www.unepfi.org/psi/ww-group/
                      </a>
                    </li>
                    <li>
                      Link to Group ESG Policy -{' '}
                      <a href="https://www.ww-ag.com/en/about-us/Sustainability">
                        https://www.ww-ag.com/en/about-us/Sustainability
                      </a>
                    </li>
                    <li>
                      Link to Whistleblowing Policy -{' '}
                      <a href="https://www.ww-amd.com/whistleblowing-policy">
                        https://www.ww-amd.com/whistleblowing-policy
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  WWAMI adheres to the ESG restrictions as prescribed by the Investment Advisor on a
                  per fund basis. Currently the Investment Advisor for the applicable funds under
                  SRD II is also a subsidiary of W&amp;W AM GMBH.
                </li>
                <li>ESG restrictions are passed onto Investment Advisors as required.</li>
              </ul>
            </li>
            <li>
              Investee Dividend Policy;
              <ul>
                <li>
                  The current default position on investees issuing of dividends is to take cash
                  payment unless it is deemed more beneficial for the fund to do otherwise.
                </li>
              </ul>
            </li>
            <li>
              Voting Policy;
              <ul>
                <li>
                  WWAMI do not exercise voting rights with investees for the applicable shares, as
                  per SRD II. Voting rights policy is determined by the fund manager on a per fund
                  basis and WWAMI will act according to policy as per the Prospectus of that fund.
                  Current applicable fund prospectus mandates that the Investment Manager does not
                  exercise voting rights.
                </li>
              </ul>
            </li>
            <li>
              Cooperation and communication with other shareholders, relevant stakeholders, and
              conducting dialogue with investee companies;
              <ul>
                <li>
                  Current strategy does not facilitate communication with investees, other
                  shareholders, or stakeholders. The current strategy is to invest in a large number
                  of investment grade securities across multiple indices and communication at the
                  investee level is deemed not feasible or beneficial to the fund/investor. If a
                  change in current investment strategy is enacted, this policy will be reviewed.
                </li>
              </ul>
            </li>
            <li>
              WWAMI manages actual and potential conflicts of interest in relation to its engagement
              by:
              <ul>
                <li>Maintaining an up to date Conflict of Interest Policy</li>
                <li>Maintaining a Conflict of Interest Register </li>
                <li>Receiving Quarterly Employee Conflict of Interest declarations </li>
                <li>Receiving Quarterly Directors Conflict of Interest declarations</li>
                <li>Maintaining and enforcing a Personal Account Dealing Policy</li>
                <li>Enacting a Company Code of Conduct </li>
                <li>Maintaining a Conduct Risk Policy.</li>
              </ul>
            </li>
          </ul>
        </p>

        <h3>Annual Engagement and Investor (Client) Reporting Policy </h3>
        <p>
          Under SRD II (1110H, (4)), a relevant asset manager that has developed an engagement
          policy shall, on an annual basis, publicly disclose how its engagement policy has been
          implemented. WWAMI shall, on an annual basis, disclose on its website how it has
          implemented its Shareholder Engagement Policy. Under SRD II (1110J, (4)), where a relevant
          asset manager invests on behalf of a relevant institutional investor through a collective
          investment undertaking, the relevant asset manager shall disclose, on an annual basis to
          the institutional investor how its investment strategy complies with that mandated
          investment strategy and contributes to the medium to long-term performance of the assets
          of the funds. WWAMI, on an annual basis, will report on the following items:
          <ul>
            <li>the key material medium to long-term risks associated with the investments,</li>
            <li>portfolio composition,</li>
            <li>turnover and turnover costs,</li>
            <li>the use of proxy advisors for the purpose of engagement activities, and</li>
            <li>
              the asset manager’s policy on securities lending and how it is applied to engagement
              activities
            </li>
            <li>
              how the relevant asset manager makes investments decisions based on its evaluation of
              medium to long-term performance of the investee company, including non-financial
              performance, and
            </li>
            <li>
              whether and, if so, which conflicts of interest have arisen in connection with
              engagement activities and how the asset manager has dealt with them.
            </li>
          </ul>
        </p>
        <h3>2023 SRD II Declaration</h3>
        <p>
          As part of the SRD II’s disclosure requirements, WWAMI must disclose annually via its
          website how it has implemented SRD II in a way that meets the requirements of the
          Directive.
        </p>
        <p>
          In 2023, the strategies of the applicable funds under SRD II did not facilitate the
          exercising of voting rights or communication with investee companies, as it would not have
          been in the best interest of the funds or our clients to do so.
        </p>
      </section>
    </Layout>
  );
};

export default ShareholderEngagementPolicy;
